<template>
  <div v-if="this.items.length > 0">
    <div v-for="(items, key) in chunkedItems" class="row mb-4" :key="key">
      <div class="col-md-6" v-for="(item, index) in items" :key="index">
      <div class="product-list border-0">
        <h5>{{ item.name }}</h5>
        <div v-html="item.content"/>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Module1",
  props: {
    items: {type: Array, required: true},
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.items, 2)
    }
  }
}
</script>

<style scoped>

</style>